import React from "react"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const NotFound: React.FC<PageProps> = ({}) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/transform-1.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Privacy"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            Page not found
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Page not found" />
    <div className="container py-10 max-w-[700px] leading-tight">
      <h1>Sorry, we couldn’t find the page you where looking for.</h1>
    </div>
  </Layout>
)

export default NotFound
